import React from 'react';
import './App.css';
import StreamViewer from './StreamViewer';
import SupportingBanner from './SupportingBanner'
import DonateButton from './DonateButton'
import InfoBox from './InfoBox'
import NotLive from './NotLive'
import FaqBox from './FaqBox'
import Loading from './Loading'
import firebase from './firebase.js'

class App extends React.Component {
  constructor() {
    super()
    this.state = {
      isLive: false,
      causeLogo: '',
      causeName: '',
      twitchUrl: '',
      donateUrl: '',
      isLoading: true,
    }

    firebase.database().ref('public').on("value", this.onData.bind(this))
  }

  onData(snapshot) {
    if (snapshot.val() != null) {
      var data = snapshot.val()
      this.setState({
        isLive: data.streamData.isLive,
        twitchUrl: data.streamData.twitchUrl,
        twitchName: data.streamData.twitchUrl.split("https://www.twitch.tv/")[1],
        causeLogo: data.campaignData.CauseProfileURL,
        causeName: data.campaignData.CauseName,
        donateUrl: data.campaignData.DonateURL,
        isLoading: false
      })
    }
  }

  render() {
    var main

    if (this.state.isLoading) {
      main = <Loading/>
    } else {
      if (this.state.isLive) {
        main = <>
        <SupportingBanner 
        logo= {this.state.causeLogo}
        causeName={this.state.causeName}/>
                <StreamViewer twitchUrl={this.state.twitchName}/>
        <InfoBox/>
        <DonateButton url={this.state.donateUrl}/>
        </>
      } else {
        main = <NotLive url={this.state.twitchUrl}/>
      }
    }

    return <div className="App" id="container">
        <h1>COINPUSHERFORCHARITY.COM</h1>
        {main}
        <footer>made with ❤️ by <a href="https://twitter.com/toficofi">@toficofi</a></footer>
        <FaqBox/>
      </div>
  }
}

export default App;
