import React from 'react';
import './SupportingBanner.css';
import Config from './Config.js'

class SupportingBanner extends React.Component {
    render () {
      return <div id="SupportingBanner">
          <div id="text">
            <div id="supporting">SUPPORTING</div>
            <div id="causeName">{this.props.causeName}</div>
          </div>
          <div id="logo">
          <img src={this.props.logo}/>
          </div>
      </div>
    }
}

export default SupportingBanner;
