import React from 'react';
import './InfoBox.css';
import Config from './Config.js'

class InfoBox extends React.Component {
    render () {
      return <div id="InfoBox">
          <div id="BulletPoints">
              <div className="bulletpoint">
                  <img className="point" src="/bulletpoint.png"/><span className="text">Donations are made through <a href="https://tiltify.com">Tiltify</a></span>
              </div>
              <div className="bulletpoint">
                  <img className="point" src="/bulletpoint.png"/><span className="text">Put your Twitch name in the name box!</span>
              </div>
              <div className="bulletpoint">
                  <img className="point" src="/bulletpoint.png"/><span className="text">You’ll get more coins if you don’t round up, like $3.49</span>
              </div>
          </div>
          <div id="YellowBox">
            Once you’ve made your donation, switch back to Twitch to see your coins appear!
          </div>
      </div>
    }
}

export default InfoBox;
