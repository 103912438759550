import React from 'react';
import './TwitchButton.css';
import Config from './Config.js'

class NotLive extends React.Component {
    render () {
      return <button class="TwitchButton" onClick={() => window.location = this.props.url}>
          <div class="Icon"><img src="twitch-logo.png"></img></div>
          <div class="Text">Follow</div>
      </button>
    }
}

export default NotLive;
