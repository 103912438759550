import React from 'react';
import "./Loading.css"
import PuffLoader from "react-spinners/PuffLoader"
import Config from './Config.js'

class Loading extends React.Component {
    render () {
      return <div id="Loading">
          <PuffLoader size="100px" color="#ffa619"/>
      </div>
    }
}

export default Loading;
