import React from 'react';
import './NotLive.css';
import TwitchButton from "./TwitchButton"
import Config from './Config.js'

class NotLive extends React.Component {
    render () {
      return <div id="NotLive">
          <div className="Header">
              We're not live right now.
          </div>

          <div className="Body">
          You can get notified when we’re next streaming by following on Twitch or <a href="https://twitter.com/toficofi">checking @toficofi on Twitter</a>
          </div>

          <div className="TwitchButtonContainer">
              <TwitchButton url={this.props.url}/>
          </div>
      </div>
    }
}

export default NotLive;
