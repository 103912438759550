import React from 'react';
import './FaqBox.css';
import Config from './Config.js'

class InfoBox extends React.Component {
    render () {
      return <div id="FaqBox">
          <div id="Title">
              What is this thing?
          </div>
          <div id="Body">
          It’s a little project by Josh <a href="https://twitter.com/toficofi">(@toficofi on Twitter)</a> where a coin pusher game is streamed to Twitch, and donations to a selected charity are converted into coins. 
        <br/><br/>The goal is to drop coins into the pusher, and get them to fall into the bucket at the end.


        <video autoPlay muted loop>
            <source src="video-demo.mp4" type="video/mp4"/>
            Your browser does not support the video tag.
        </video>

        <div className="header">Do I need a Twitch account to play?</div>

        Nope! You can still drop coins in without a Twitch account. However, putting in your Twitch name will engrave your profile picture on your coins!
        
        <div className="Image">
        <img src="engraved-coin.png"></img>
        </div>

        <div className="header">When is the Coin Pusher streamed?</div>
        I’m still getting a feel for this project as it’s really new, so I’m not sure. The best way to find out is to watch my Twitter and follow the Twitch account for notifications.

        <div className="header">Who are you?</div>
        I’m Josh, a game development student based in Dundee, Scotland. <a href="https://twitter.com/toficofi">I’m most active on Twitter</a>, but you can also <a href="mailto:josh@joshbe.me">email me at josh@joshbe.me</a>
        
        <div id="Me" className="Image">
            <img src="me.png"></img>
        </div>
        </div>
      </div>
    }
}

export default InfoBox;
