import React from 'react';
import './StreamViewer.css';
import Config from './Config.js'
import { TwitchPlayer } from 'react-twitch-embed'

class StreamViewer extends React.Component {
    render () {
      return <div id="StreamViewer">
          <TwitchPlayer channel={this.props.twitchUrl} width="100%" height="300px"/>
      </div>
    }
}

export default StreamViewer;
