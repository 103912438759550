import React from 'react';
import './DonateButton.css';
import Config from './Config.js'

class DonateButton extends React.Component {
    render () {
      return <div id="DonateButtonContainer">
                <button id="DonateButton" onClick={() => window.location.href = this.props.url}>
                  Donate for coins
                 </button>

                 <div id="MinimumDonation">Minimum donation is $1.00</div>
        </div>

    }
}

export default DonateButton;
