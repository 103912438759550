import firebase from 'firebase'
const config = {
    apiKey: "AIzaSyAzugABzBB3XJIKI4UKyWFyWD7l1HljGUU",
    authDomain: "coin-pusher-for-charity.firebaseapp.com",
    databaseURL: "https://coin-pusher-for-charity.firebaseio.com",
    projectId: "coin-pusher-for-charity",
    storageBucket: "coin-pusher-for-charity.appspot.com",
    messagingSenderId: "481671989116",
    appId: "1:481671989116:web:485e919183dc953e4dc6f9",
    measurementId: "G-X3FGEXPN9X"
};
firebase.initializeApp(config);
export default firebase;